import {Component, OnInit} from '@angular/core';
import {Observable, Observer} from 'rxjs';
import {SocketService} from 'src/app/services/Socket.service';
import {share} from 'rxjs/operators';
import {Howl} from 'howler';

@Component({
  selector: 'app-joueur2',
  templateUrl: './joueur2.component.html',
  styleUrls: ['./joueur2.component.scss']
})
export class Joueur2Component implements OnInit {


  nameB: string = 'Joueur 2';
  public number2: number = 8000;
  public observable: Observable<boolean>;
  private observer!: Observer<boolean>;

  constructor(private socketService: SocketService) {
    this.observable = new Observable<boolean>((observer: any) => this.observer = observer).pipe(share());
    setTimeout(() => this.observer.next(true));
    this.socketService.getPvb();
  }

  ngOnInit(): void {


    var sound = new Howl({
      src: ['assets/points_sound_effect.ogg'],
      html5: true
    });

    this.socketService.onNameB()
      .subscribe((message: string) => {
        this.nameB = message;
      });


    this.socketService.onPvB()
      .subscribe((message: string) => {
        this.number2 = Number(message);
        sound.play();
        setTimeout(() => this.observer.next(true), 1000);
      });
  }

}
