<div class="container">
  <div class="row">
    <div class="col-sm">
      <app-obs [isPanel]="true"></app-obs>
    </div>
    <div class="col-sm">
      <app-name-a></app-name-a>
      <app-name-b></app-name-b>
    </div>
    <div class="col-sm">
      <app-pv-a></app-pv-a>
      <app-pv-b></app-pv-b>
    </div>
  </div>
</div>