<app-header></app-header>

<div class="container-fluid">
  <div class="row">
    <div class="col-sm-3" style="margin-top: 3%;">
      <div class="chat_dialog">
        <app-monitor></app-monitor>
      </div>
      <button (click)="freeMonitor()" class="btn btn-danger suppCard" style="width: 100%; margin: 10px 0;">
        <i class="fas fa-trash-alt"></i>
        Retirer Carte
      </button>
      <button (click)="freeImage()" class="btn btn-danger suppCard" style="width: 100%; margin: 10px 0;">
        <i class="fas fa-trash-alt"></i>
        Retirer Image
      </button>
      <button (click)="clearDeck()" class="btn btn-danger suppCard" style="width: 100%; margin: 10px 0;">
        <i class="fas fa-trash-alt"></i>
        Retirer decks
      </button>
      <div class="row">
        <div class="col-md-12" style="border: 1px solid black;">
          <div class="form-group">
            <div class="row">
              <div class="col-8">
                <label for="selectVS">Joueur A</label>
              </div>
              <div class="col-4">
                <label>LifePoint</label>
              </div>
            </div>
            <div class="row">
              <div class="col-8">
                <input (keyup.enter)="setNameA()" [formControl]="nameA" class="form-control pointAll nameInputA"
                       type="text"/>
              </div>
              <div class="col-4">
                <input (keyup.enter)="setPvA()" [formControl]="pvA" class="form-control pointAll pointInputA"
                       type="text"/>
              </div>
            </div>
          </div>

          <div class="form-group">
            <div class="row">
              <div class="col-8">
                <label for="selectVS">Joueur B</label>
              </div>
              <div class="col-4">
                <label>LifePoint</label>
              </div>
            </div>
            <div class="row">
              <div class="col-8">
                <input (keyup.enter)="setNameB()" [formControl]="nameB" class="form-control pointAll nameInputB"
                       type="text"/>
              </div>
              <div class="col-4">
                <input (keyup.enter)="setPvB()" [formControl]="pvB" class="form-control pointAll pointInputB"
                       type="text"/>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-center">
            <button (click)="setDuel()" class="btn  btn-primary" style=" margin: 1%;">Duel</button>
          </div>
          <app-pvcontrol></app-pvcontrol>
        </div>
        <div class="col-md-12" style="border: 1px solid black; margin-top: 5%;">
          <div class="row">
            <input (keyup.enter)="setTimer()" [formControl]="timer" placeholder="Entrez le time..." style="margin: 2%;"
                   type="text">
            <button (click)="playTimer()" class="btn  btn-primary" style="margin: 1%;">Play</button>
            <button (click)="pauseTimer()" class="btn  btn-primary" style="margin: 1%;">Pause</button>
            <button (click)="stopTimer()" class="btn  btn-primary" style="margin: 1%;">Stop</button>
          </div>
          <div class="chat_time">
            <app-timer></app-timer>
          </div>
        </div>
      </div>

    </div>
    <div class="col-sm-9" role="main">
      <app-search></app-search>
    </div>
  </div>
</div>
