<div class="espace"></div>
<mat-accordion>
    <mat-expansion-panel>
        <mat-expansion-panel-header>
            <mat-panel-title>
                Recherche de Carte
            </mat-panel-title>
            <mat-panel-description (keydown.Space)="$event.stopPropagation()">
                <input (keyup.enter)="findCard()" [formControl]="name" placeholder="Entrez un nom de carte..." type="text">
                <button (click)="findCard()" class="btn btn-dark">Rechercher</button>
                <input checked data-off="EN" data-offstyle="outline-warning" data-on="FR" data-onstyle="outline-primary" data-toggle="toggle" id="toggle-event" type="checkbox">
                <input (keyup.enter)="setOtherDeckList()" [formControl]="otherDeckklist" placeholder="ID Deck" type="text">
            </mat-panel-description>
        </mat-expansion-panel-header>
        <input (keyup.enter)="addCardToOnglet()" [formControl]="otherCard" placeholder="url Img Carte" style="margin: 1%;width:400px" type="text">
        <input (keyup.enter)="addImageToOnglet()" [formControl]="oversizedImage" placeholder="url Img Taille Réelle" style="margin: 1%;width:400px" type="text">
    </mat-expansion-panel>
</mat-accordion>

<mat-tab-group [selectedIndex]="nombre" dynamicHeight>
    <mat-tab *ngIf=" cards.length !=0 ">
        <ng-template mat-tab-label>
            <div class="col" style="margin-left: 20px;">Carte</div>
            <button (click)="closeTab(1)" aria-label="Close" class="close" type="button">
        <span aria-hidden="true">&times;</span>
      </button>
        </ng-template>
        <div *ngIf=" cards.length !=0 ">
            <ul style="height:720px;width:1400px;overflow:auto">
                <img (click)="sendToObsCardL($event)" (contextmenu)="sendToObsCardR($event)" (error)="onImgDeckError($event,card)" *ngFor="let card of cards" [src]="language ? card.urlFR : card.urlEN" height="345" style="margin: .5%;" width="237">
            </ul>
        </div>
    </mat-tab>



    <mat-tab *ngIf="deck1.name != ''">
        <ng-template mat-tab-label>
            <div class="col" style="margin-left: 20px;">D1 : {{deck1.name}}</div>
        </ng-template>

        <div style="height:720px;width:1400px;overflow:auto">
            <div>
                <ul style="overflow:auto">
                    <h3>Main Deck</h3>
                    <img (click)="sendToObsCardL($event)" (contextmenu)="sendToObsCardR($event)" (error)="onImgDeckError($event,cardmain)" *ngFor="let cardmain of deck1.main" [src]="language ? cardmain.urlFR : cardmain.urlEN" height="172" style="margin: .5%;" width="118">
                </ul>
            </div>
            <div>
                <ul style="overflow:auto">
                    <h3>Side Deck</h3>
                    <img (click)="sendToObsCardL($event)" (contextmenu)="sendToObsCardR($event)" (error)="onImgDeckError($event,cardside)" *ngFor="let cardside of deck1.side" [src]="language ? cardside.urlFR : cardside.urlEN" height="172" style="margin: .5%;" width="118">
                </ul>
            </div>
            <div>
                <ul style="overflow:auto">
                    <h3>Extra Deck</h3>
                    <img (click)="sendToObsCardL($event)" (contextmenu)="sendToObsCardR($event)" (error)="onImgDeckError($event,cardextra)" *ngFor="let cardextra of deck1.extra" [src]="language ? cardextra.urlFR : cardextra.urlEN" height="172" style="margin: .5%;" width="118">
                </ul>
            </div>
        </div>

    </mat-tab>

    <mat-tab *ngIf="deck2.name != ''">
        <ng-template mat-tab-label>
            <div class="col" style="margin-left: 20px;">D2 : {{deck2.name}}</div>
        </ng-template>

        <div style="height:720px;width:1400px;overflow:auto">
            <div>
                <ul style="overflow:auto">
                    <h3>Main Deck</h3>
                    <img (click)="sendToObsCardL($event)" (contextmenu)="sendToObsCardR($event)" (error)="onImgDeckError($event,cardmain)" *ngFor="let cardmain of deck2.main" [src]="language ? cardmain.urlFR : cardmain.urlEN" height="172" style="margin: .5%;" width="118">
                </ul>
            </div>
            <div>
                <ul style="overflow:auto">
                    <h3>Side Deck</h3>
                    <img (click)="sendToObsCardL($event)" (contextmenu)="sendToObsCardR($event)" (error)="onImgDeckError($event,cardside)" *ngFor="let cardside of deck2.side" [src]="language ? cardside.urlFR : cardside.urlEN" height="172" style="margin: .5%;" width="118">
                </ul>
            </div>
            <div>
                <ul style="overflow:auto">
                    <h3>Extra Deck</h3>
                    <img (click)="sendToObsCardL($event)" (contextmenu)="sendToObsCardR($event)" (error)="onImgDeckError($event,cardextra)" *ngFor="let cardextra of deck2.extra" [src]="language ? cardextra.urlFR : cardextra.urlEN" height="172" style="margin: .5%;" width="118">
                </ul>
            </div>
        </div>

    </mat-tab>

    <mat-tab *ngFor="let deck of autreDeck">
        <ng-template mat-tab-label>
            <div class="col" style="margin-left: 20px;">{{deck.name}}</div>
            <button (click)="closeTabDeck(deck)" aria-label="Close" class="close" type="button">
        <span aria-hidden="true">&times;</span>
      </button>
        </ng-template>

        <div style="height:720px;width:1400px;overflow:auto">
            <div>
                <ul style="overflow:auto;">
                    <div class="row">
                        <h3 style=" margin: 1%;">Main Deck</h3>
                        <button (click)="setDeck1(deck)" class="btn btn-primary" style=" margin: 1%;">Display Deck
              1
            </button>
                        <button (click)="setDeck2(deck)" class="btn btn-secondary" style=" margin: 1%;">Display Deck
              2
            </button>
                    </div>
                    <img (click)="sendToObsCardL($event)" (contextmenu)="sendToObsCardR($event)" (error)="onImgDeckError($event,cardmain)" *ngFor="let cardmain of deck.main" [src]="language ? cardmain.urlFR : cardmain.urlEN" height="172" style="margin: .5%;" width="118">
                </ul>
            </div>
            <div>
                <ul style="overflow:auto">
                    <h3>Side Deck</h3>
                    <img (click)="sendToObsCardL($event)" (contextmenu)="sendToObsCardR($event)" (error)="onImgDeckError($event,cardside)" *ngFor="let cardside of deck.side" [src]="language ? cardside.urlFR : cardside.urlEN" height="172" style="margin: .5%;" width="118">
                </ul>
            </div>
            <div>
                <ul style="overflow:auto">
                    <h3>Extra Deck</h3>
                    <img (click)="sendToObsCardL($event)" (contextmenu)="sendToObsCardR($event)" (error)="onImgDeckError($event,cardextra)" *ngFor="let cardextra of deck.extra" [src]="language ? cardextra.urlFR : cardextra.urlEN" height="172" style="margin: .5%;" width="118">
                </ul>
            </div>
        </div>

    </mat-tab>

    <mat-tab *ngIf=" urlCarte.length !=0 ">
        <ng-template mat-tab-label>
            <div class="col" style="margin-left: 20px;">Carte Externe</div>
            <button (click)="closeTab(2)" aria-label="Close" class="close" type="button">
        <span aria-hidden="true">&times;</span>
      </button>
        </ng-template>
        <div *ngIf=" urlCarte.length !=0 ">
            <ul style="height:720px;width:1400px;overflow:auto">
                <img (click)="sendCardOther($event)" *ngFor="let card of urlCarte" [src]="cleanURL(card)" frameborder="0" height="345" scrolling="no" style="margin: .5%;" width="237">
            </ul>
        </div>
    </mat-tab>
    <mat-tab *ngFor="let image of urlImage">
        <ng-template mat-tab-label>
            <div class="col" style="margin-left: 20px;">Image {{urlImage.indexOf(image)}}</div>
            <button (click)="closeTabOther(image)" aria-label="Close" class="close" type="button">
        <span aria-hidden="true">&times;</span>
      </button>
        </ng-template>
        <div>
            <ul style="height:720px;width:1400px;overflow:auto">
                <img (click)="sendOversize($event)" [src]="cleanURL(image)" style="margin: .5%;">
            </ul>
        </div>
    </mat-tab>
    <mat-tab *ngIf=" urlCarteHisto.length !=0 ">
        <ng-template mat-tab-label>
            <div class="col" style="margin-left: 20px;">Carte Historique</div>
            <button (click)="closeTab(3)" aria-label="Close" class="close" type="button">
        <span aria-hidden="true">&times;</span>
      </button>
        </ng-template>
        <div *ngIf=" urlCarteHisto.length !=0 ">
            <ul style="height:720px;width:1400px;overflow:auto">
                <img (click)="sendToObsCardL($event)" (contextmenu)="sendToObsCardR($event)" *ngFor="let card of urlCarteHisto" [src]="cleanURL(card)" frameborder="0" height="172" scrolling="no" style="margin: .5%;" width="118">
            </ul>
        </div>
    </mat-tab>

    <mat-tab *ngIf=" pvHisto.length !=0 ">
        <ng-template mat-tab-label>
            <div class="col" style="margin-left: 20px;">PV Historique</div>
            <button (click)="closeTab(4)" aria-label="Close" class="close" type="button">
        <span aria-hidden="true">&times;</span>
      </button>
        </ng-template>
        <div *ngIf=" pvHisto.length !=0 ">
            <ul class="social-box" style="height:720px;width:1400px;overflow:auto">
                <div *ngFor="let histo of pvHisto" class="box col-lg-1 col-xs-2  text-center" style="margin: .5%;">
                    <div class="box-title">
                        <h6>Joueur {{histo.idPlayer}}</h6>
                    </div>
                    <div class="box-text">
                        <strong>{{histo.pv}}</strong>
                        <br>
                        <span>{{histo.date | date :'HH:mm:ss '}}</span>
                    </div>
                    <div class="box-btn">
                        <a (click)="sendHistoPv(histo)">Retour</a>
                    </div>
                </div>
            </ul>
        </div>
    </mat-tab>


    <mat-tab *ngFor="let deckCYGO of deckCYGO$ | async">
        <ng-template mat-tab-label>
            <div class="col" style="margin-left: 20px;">DECK : {{deckCYGO.type}}</div>
        </ng-template>
        <div *ngIf=" deckCYGO.cards ">
            <ul class="social-box" style="height:720px;width:1400px;overflow:auto">
                <div *ngFor="let decks of deckCYGO.cards" class="box col-lg-2 col-xs-1  text-center" style="margin: .5%;">
                    <div class="box-title" (click)="setOtherDeckListFromGsheet(decks.id)">
                        <h6>{{decks.name}}</h6>
                    </div>
                </div>
            </ul>
        </div>
    </mat-tab>
</mat-tab-group>