export class Extension {

  name: string;
  date: string;

  constructor() {

    this.name = '';
    this.date = '';
  }
}
