import {Component, OnInit} from '@angular/core';
import {SocketService} from 'src/app/services/Socket.service';
import {ActivatedRoute} from '@angular/router';
import {CardsOutput, DuelingBookOutputDeckClasse} from 'src/app/services/duelingbook/models/dueling-book-output-deck-classe';

@Component({
  selector: 'app-deck',
  templateUrl: './deck.component.html',
  styleUrls: ['./deck.component.scss']
})
export class DeckComponent implements OnInit {

  deck = new DuelingBookOutputDeckClasse();
  language: boolean = true;

  constructor(private socketService: SocketService,
              private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      let id: number = +params['id'];
      if (id == 1) {
        this.socketService.onDeck1().subscribe((data: DuelingBookOutputDeckClasse) => {
          this.deck = data;
        });
      } else {

        this.socketService.onDeck2().subscribe((data: DuelingBookOutputDeckClasse) => {
          this.deck = data;
        });

      }
    });
    this.socketService.onClearDeck().subscribe(() => {
      this.deck = new DuelingBookOutputDeckClasse();
    });

  }

  onImgDeckError(event: any, card: CardsOutput) {
    event.target.src = card.urlEN;
  }
}
