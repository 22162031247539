<div style="color: white;">
  <p class="points"
     id="points"
     style="background-color: #4977bb; font-weight: bold; position: absolute; width: 210px; text-align: center; height: 55px; font-size: 36px;">
    <ng9-odometer [config]="{ auto: false }" [format]="'d'" [number]="number2" [observable]="observable"></ng9-odometer>
  </p>
  <p class="text-uppercase d-inline name" id="name"
     style="background-color: #113d8d; font-weight: bold; position: absolute; width: 323px; left: 210px; height: 55px; text-align: center; font-size: 36px;">
    {{nameB}}</p>
</div>
