import {Component, Input, OnInit} from '@angular/core';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {ActivatedRoute} from '@angular/router';
import {SocketService} from 'src/app/services/Socket.service';
import {Event} from '../../../models/Event.enum';

@Component({
  selector: 'app-obs',
  templateUrl: './obs.component.html',
  styleUrls: ['./obs.component.scss']
})
export class ObsComponent implements OnInit {

  image: string = '';
  imageOverSized: string = '';
  pixel: string = '';

  @Input()
  isPanel: boolean = false;

  doCenter: string = '';

  constructor(
    private route: ActivatedRoute,
    private socketService: SocketService,
    private sanitizer: DomSanitizer) {

  }

  ngOnInit() {

    this.route.params.subscribe(params => {
      let nonbinary = params['orientation'];

      if (nonbinary && nonbinary === 'left') {
        this.socketService.onCardL()
          .subscribe((message: string) => {
            this.image = message;
          });
      } else if (nonbinary && nonbinary === 'right') {
        this.socketService.onCardR()
          .subscribe((message: string) => {
            this.image = message;
          });
      } else if (nonbinary && nonbinary === 'other') {
        this.socketService.onCardOther()
          .subscribe((message: string) => {
            this.image = message;
          });
      } else if (nonbinary && nonbinary === 'oversize') {
        this.socketService.onCardOversize()
          .subscribe((message: string) => {
            this.imageOverSized = message;
          });
      } else if (nonbinary && nonbinary === 'pixel') {
        this.socketService.onPixel()
          .subscribe((message: string) => {
            this.pixel = message;
          });
      } else if (nonbinary && nonbinary === 'all') {
        this.doCenter = 'center-card';
        this.socketService.onCardOversize()
          .subscribe((message: string) => {
            this.imageOverSized = message;
            this.image = '';
            this.pixel = '';
          });

        this.socketService.onCard()
          .subscribe((message: string) => {
            this.image = message;
            this.imageOverSized = '';
            this.pixel = '';
          });

        this.socketService.onPixel()
          .subscribe((message: string) => {
            this.image = '';
            this.imageOverSized = '';
            this.pixel = message;
          });

      } else {
        this.socketService.onCard()
          .subscribe((message: string) => {
            this.image = message;
            this.imageOverSized = '';
            this.pixel = '';
          });
      }
      if (this.isPanel) {
        this.doCenter = 'center-card';
        this.socketService.onCardOversize()
          .subscribe((message: string) => {
            this.imageOverSized = message;
            this.image = '';
            this.pixel = '';
          });
        this.socketService.onPixel()
          .subscribe((message: string) => {
            this.imageOverSized = '';
            this.image = '';
            this.pixel = message;
          });
      }
    });


    this.socketService.onClear()
      .subscribe(() => {
        this.image = '';
      });

    this.socketService.onClearReele()
      .subscribe(() => {
        this.imageOverSized = '';
      });


    this.socketService.onEvent(Event.CONNECT)
      .subscribe(() => {
        console.log('connected');
      });

    this.socketService.onEvent(Event.DISCONNECT)
      .subscribe(() => {
        console.log('disconnected');
      });

  }

  onImgError(event: any) {
    event.style.display = 'none';
  }

  cleanURL(oldURL: string): SafeUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(oldURL);
  }
}
