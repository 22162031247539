import {Injectable} from '@angular/core';
import {AuthService} from '../auth.service';
import {SocketService} from '../Socket.service';

@Injectable({
  providedIn: 'root'
})
export class PixelcomService extends SocketService {

  constructor(authService: AuthService) {
    super(authService);
  }
}
