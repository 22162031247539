import {Component, OnInit} from '@angular/core';
import {SocketService} from 'src/app/services/Socket.service';

@Component({
  selector: 'app-pv-a',
  templateUrl: './pv-a.component.html',
  styleUrls: ['./pv-a.component.scss']
})
export class PvAComponent implements OnInit {

  ioConnection: any;
  pvA: string = '';

  constructor(private socketService: SocketService) {
  }

  ngOnInit() {


    this.ioConnection = this.socketService.onPvA()
      .subscribe((message: string) => {
        this.pvA = message;
      });

  }

}
