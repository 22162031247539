<div class="wrapper fadeInDown">
  <div id="formContent">

    <form (ngSubmit)="signIn()" [formGroup]="authForm" class="login-container">

      <p>
        <input class="fadeIn third" formControlName="token" id="password" placeholder="password" type="password">
      </p>
      <p>
        <input class="fadeIn fourth" type="submit" value="Log In">
      </p>
    </form>

  </div>
</div>
