<div *ngIf="deck.name != ''">
  <ul style="overflow:auto;block-size: 75%;">
    <h2 id="deck">{{deck.name}}</h2>
    <h3>Main Deck</h3>
    <img (error)="onImgDeckError($event,cardmain)" *ngFor="let cardmain of deck.main" [src]="language ? cardmain.urlFR : cardmain.urlEN" deck
         height="131.25" style="margin-left: 1px;margin-bottom: 1px;"
         width="88.5">
  </ul>
</div>
<div *ngIf="deck.name != ''">
  <ul style="overflow:auto">
    <h3>Side Deck</h3>
    <img (error)="onImgDeckError($event,cardside)" *ngFor="let cardside of deck.side" [src]="language ? cardside.urlFR : cardside.urlEN" deck
         height="103.2" style="margin-left: 1px;margin-bottom: 1px;"
         width="70.8">
  </ul>
</div>
<div *ngIf="deck.name != ''">
  <ul style="overflow:auto">
    <h3>Extra Deck</h3>
    <img (error)="onImgDeckError($event,cardextra)" *ngFor="let cardextra of deck.extra" [src]="language ? cardextra.urlFR : cardextra.urlEN" deck
         height="103.2" style="margin-left: 1px;margin-bottom: 1px;"
         width="70.8">
  </ul>
</div>
