import {Component, OnInit} from '@angular/core';
import {AuthService} from 'src/app/services/auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  isShowed = false;

  constructor(
    public authService: AuthService) {
  }

  ngOnInit(): void {
  }

  showRoom() {
    if (this.isShowed === false) {
      this.isShowed = true;
    } else {
      this.isShowed = false;
    }

  }

}
