import {Component, OnInit} from '@angular/core';
import {AuthService} from 'src/app/services/auth.service';

const baseUrl = 'https://iqkorpr95aug2c-ygopv.functions.fnc.fr-par.scw.cloud/';

@Component({
  selector: 'app-infos',
  templateUrl: './infos.component.html',
  styleUrls: ['./infos.component.scss']
})
export class InfosComponent implements OnInit {

  constructor(public authService: AuthService) {
  }

  ngOnInit(): void {
  }

  openPopPup() {

  }

  public getCartes(): string {
    this.openPopPup();
    return baseUrl + 'obs/none';
  }

  public getCartesR(): string {
    this.openPopPup();
    return baseUrl + 'obs/right';
  }

  public getCartesL(): string {
    this.openPopPup();
    return baseUrl + 'obs/left';
  }

  public getTimer(): string {
    this.openPopPup();
    return baseUrl + 'timer';
  }

  public getJ1(): string {
    this.openPopPup();
    return baseUrl + 'joueur1';
  }

  public getJ2(): string {
    this.openPopPup();
    return baseUrl + 'joueur2';
  }

  public getNJ1(): string {
    this.openPopPup();
    return baseUrl + 'nameA';
  }

  public getNJ2(): string {
    this.openPopPup();
    return baseUrl + 'nameB';
  }

  public getPJ1(): string {
    this.openPopPup();
    return baseUrl + 'pvA';
  }

  public getPJ2(): string {
    this.openPopPup();
    return baseUrl + 'pvB';
  }

}
