import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Event} from '../models/Event.enum';

import * as socketIo from 'socket.io-client';
import {AuthService} from './auth.service';
import {DuelingBookOutputDeckClasse} from './duelingbook/models/dueling-book-output-deck-classe';

const SERVER_URL = 'https://back.ygo.iqkorp.com/';

@Injectable({
  providedIn: 'root'
})
export class SocketService {

  private socket: any;

  constructor(authService: AuthService) {
    console.log(authService.getToken());
    this.socket = socketIo(SERVER_URL + authService.getToken(), {
      'transports': ['websocket']
    });
    console.log('created');
  }

  public sendCardL(images: string): void {
    this.socket.emit('images-left', images);
  }


  public sendCardR(images: string): void {
    this.socket.emit('images-right', images);
  }

  public sendCardOther(images: string): void {
    this.socket.emit('images-other', images);
  }

  public sendCardOverSize(images: string): void {
    this.socket.emit('images-oversize', images);
  }

  public sendCardPixel(images: string): void {
    this.socket.emit('images-pixel', images);
  }

  public sendNameA(nameA: string): void {
    this.socket.emit('noma', nameA);
  }

  public sendNameB(nameB: string): void {
    this.socket.emit('nomb', nameB);
  }

  public sendPvA(pvA: string): void {
    this.socket.emit('pva', pvA);
  }

  public sendPvB(pvB: string): void {
    this.socket.emit('pvb', pvB);
  }

  public sendTimer(timer: string): void {
    this.socket.emit('timerset', timer);
  }

  public playTimer(): void {
    this.socket.emit('timerplay');
  }

  public pauseTimer(): void {
    this.socket.emit('timerpause');
  }

  public stopTimer(): void {
    this.socket.emit('timerstop');
  }

  public clear(): void {
    this.socket.emit('clear');
  }

  public onCard(): Observable<string> {
    return new Observable<string>(observer => {
      this.socket.on('images', (data: string) => observer.next(data));
    });
  }

  public onCardL(): Observable<string> {
    return new Observable<string>(observer => {
      this.socket.on('images-left', (data: string) => observer.next(data));
    });
  }

  public onCardR(): Observable<string> {
    return new Observable<string>(observer => {
      this.socket.on('images-right', (data: string) => observer.next(data));
    });
  }

  public onCardOther(): Observable<string> {
    return new Observable<string>(observer => {
      this.socket.on('images-other', (data: string) => observer.next(data));
    });
  }

  public onCardOversize(): Observable<string> {
    return new Observable<string>(observer => {
      this.socket.on('images-oversize', (data: string) => observer.next(data));
    });
  }

  public onPixel(): Observable<string> {
    return new Observable<string>(observer => {
      this.socket.on('images-pixel', (data: string) => observer.next(data));
    });
  }

  public onNameA(): Observable<string> {
    return new Observable<string>(observer => {
      this.socket.on('noma', (data: string) => observer.next(data));
    });
  }

  public onNameB(): Observable<string> {
    return new Observable<string>(observer => {
      this.socket.on('nomb', (data: string) => observer.next(data));
    });
  }

  public onPvA(): Observable<string> {
    return new Observable<string>(observer => {
      this.socket.on('pva', (data: string) => observer.next(data));
    });
  }

  public onPvB(): Observable<string> {
    return new Observable<string>(observer => {
      this.socket.on('pvb', (data: string) => observer.next(data));
    });
  }

  public onTimerSet(): Observable<string> {
    return new Observable<string>(observer => {
      this.socket.on('timerset', (data: string) => observer.next(data));
    });
  }

  public onClear(): Observable<string> {
    return new Observable<string>(observer => {
      this.socket.on('clear', (data: string) => observer.next(data));
    });
  }

  public onClearReele(): Observable<string> {
    return new Observable<string>(observer => {
      this.socket.on('clear-reel', (data: string) => observer.next(data));
    });
  }

  public onPlay(): Observable<string> {
    return new Observable<string>(observer => {
      this.socket.on('timerplay', (data: string) => observer.next(data));
    });
  }

  public onPause(): Observable<string> {
    return new Observable<string>(observer => {
      this.socket.on('timerpause', (data: string) => observer.next(data));
    });
  }

  public onStop(): Observable<string> {
    return new Observable<string>(observer => {
      this.socket.on('timerstop', (data: string) => observer.next(data));
    });
  }

  public onEvent(event: Event): Observable<any> {
    return new Observable<Event>(observer => {
      this.socket.on(event, () => observer.next());
    });
  }


  public getPva(): void {
    this.socket.emit('getpva');
  }

  public getPvb(): void {
    this.socket.emit('getpvb');
  }

  public onCLearCommandPV(): Observable<any> {
    return new Observable<string>(observer => {
      this.socket.on('clearCommandPV', () => observer.next());
    });
  }

  public onUpdateID(): Observable<string> {
    return new Observable<string>(observer => {
      this.socket.on('updateID', (data: string) => observer.next(data));
    });
  }

  public onUpdateScore(): Observable<string> {
    return new Observable<string>(observer => {
      this.socket.on('updateScore', (data: string) => observer.next(data));
    });
  }


  public sendDeck1(data: DuelingBookOutputDeckClasse): void {
    this.socket.emit('deck1', JSON.stringify(data));
  }

  public sendDeck2(data: DuelingBookOutputDeckClasse): void {
    this.socket.emit('deck2', JSON.stringify(data));
  }

  public onDeck1(): Observable<DuelingBookOutputDeckClasse> {
    return new Observable<DuelingBookOutputDeckClasse>(observer => {
      this.socket.on('deck1', (data: any) => observer.next(JSON.parse(data)));
    });
  }

  public onDeck2(): Observable<DuelingBookOutputDeckClasse> {
    return new Observable<DuelingBookOutputDeckClasse>(observer => {
      this.socket.on('deck2', (data: any) => observer.next(JSON.parse(data)));
    });
  }


  public clearDeck(): void {
    this.socket.emit('clearDeck');
  }

  public onClearDeck(): Observable<any> {
    return new Observable<string>(observer => {
      this.socket.on('clearDeck', () => observer.next());
    });
  }

  public clearReel(): void {
    this.socket.emit('clear-reel');
  }


}
