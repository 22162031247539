import {Extension} from './../models/Extension';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {CardsOutput} from './duelingbook/models/dueling-book-output-deck-classe';
import {forkJoin, Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

declare var html2json: any;

@Injectable({
  providedIn: 'root'
})
export class ScrapperService {

  extensions: Array<Extension>;
  //base_img = 'https://www.otk-expert.fr/cartes/yugioh_ext/&extension1&/&extension2&-&number&.jpg';
  base_img = 'https://ygocard.s3.fr-par.scw.cloud/&extension1&-&number&.jpg';

  constructor(private http: HttpClient) {
    this.extensions = this.getExtensions();
  }

  public getExtensions(): Array<Extension> {
    let extensions: Array<Extension> = Array();
    let url = 'https://db.ygoprodeck.com/api/v7/cardsets.php';
    this.http.get(url, {
      responseType: 'text'
    }).subscribe(data => {
      var json = JSON.parse(data);
      json.forEach(function(extension: any) {
        const tmp: Extension = new Extension();
        let name = extension.set_name;
        let date = extension.tcg_date;
        tmp.name = name;
        tmp.date = date;
        extensions.push(tmp);
      });
    });
    return extensions;
  }


  public findCardByName(name: string): Observable<CardsOutput[]> {
    let url = 'https://db.ygoprodeck.com/api/v7/cardinfo.php?fname=' + name + '&num=100&offset=0';

    return forkJoin([
      this.http.get(url + '&language=fr', {
        responseType: 'text'
      }).pipe(catchError(error => of(error))),
      this.http.get(url, {
        responseType: 'text'
      }).pipe(catchError(error => of(error)))
    ]).pipe(
      map(([res1, res2]) => {
        let map1: Map<number, CardsOutput> = new Map();
        let map2: Map<number, CardsOutput> = new Map();
        if (!(res1 && res1.status && res1.status != 200)) {
          map1 = this.transformToDomain(JSON.parse(res1).data);
        }
        if (!(res2 && res2.status && res2.status != 200)) {
          map2 = this.transformToDomain(JSON.parse(res2).data);
        }
        map1.forEach((value, key) => map2.set(key, value));
        return Array.from(map2.values());
      }));

  }

  public transformToDomain(data: any): Map<number, CardsOutput> {
    let resultat: Map<number, CardsOutput> = new Map();
    if (!data) {
      return resultat;
    }
    data.forEach((carte: any) => {
      let date = '';
      let value: any;
      if (carte.card_sets) {
        carte.card_sets.forEach((extension: any) => {
          this.extensions.forEach((ext: Extension) => {
            if (ext.name === extension.set_name) {
              if ((date === '' || Date.parse(date) < Date.parse(ext.date)) && !ext.name.includes('Tournament')) {
                date = ext.date;
                value = extension.set_code.split('-', 2);
                value[1] = 'FR' + value[1].substr(2);
              }
            }
          });
        });
      }
      let card = new CardsOutput();
      card.id = +carte.id;
      card.name = carte.name;
      if (carte.card_sets && value && value.length == 2) {
        card.urlFR = this.base_img.replace('&extension1&', value[0]).replace('&extension2&', value[0]);
        //card.urlFR = card.urlFR.replace('&number&', "" + Number(value[1].substr(value[1].length - 3)));
        card.urlFR = card.urlFR.replace('&number&', '' + value[1]);
      }
      card.urlEN = carte.card_images[0].image_url;
      resultat.set(card.id, card);
    });
    return resultat;
  }

}
