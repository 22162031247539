import {Component, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {interval} from 'rxjs';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-randomcard',
  templateUrl: './randomcard.component.html',
  styleUrls: ['./randomcard.component.scss']
})
export class RandomcardComponent implements OnInit {

  src: Array<string> = new Array();
  image: string = '';

  constructor(private http: HttpClient,
              private route: ActivatedRoute) {
  }

  public timeout(ms: any) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  public pickRandom() {
    var sr = this.src[Math.floor(Math.random() * this.src.length)];
    console.log(sr);
    this.image = sr;
  }

  public loop(timing: number) {
    const subscription = interval(timing).subscribe(val => this.pickRandom());
  }

  ngOnInit(): void {
    this.http.get('../../assets/ygobdd.txt', {
      responseType: 'text'
    }).subscribe(data => {
      const lines = data.split('\n');
      for (let i = 0; i < lines.length; i++) {
        const item = lines[i];
        this.src.push(item);
      }
      this.pickRandom();
      this.route.params.subscribe(params => {
        let timing: number = +params['time'];
        this.loop(timing);
      });
    });
  }

}
