import {Component, OnInit, ViewChild} from '@angular/core';
import {SocketService} from '../../../services/Socket.service';
import {Event} from '../../../models/Event.enum';
import {CountdownComponent} from 'ngx-countdown';


@Component({
  selector: 'app-timer',
  templateUrl: './timer.component.html',
  styleUrls: ['./timer.component.scss']
})
export class TimerComponent implements OnInit {

  ioConnection: any;
  timer: number = 2400;
  @ViewChild('cd', {static: false}) private countdown!: CountdownComponent;

  constructor(private socketService: SocketService) {
  }

  ngOnInit() {

    this.ioConnection = this.socketService.onTimerSet()
      .subscribe((message: string) => {
        this.timer = (Number(message)) * 60;
      });

    this.socketService.onPlay()
      .subscribe(() => {
        this.countdown.begin();
      });

    this.socketService.onPause()
      .subscribe(() => {
        this.countdown.pause();
      });

    this.socketService.onStop()
      .subscribe(() => {
        this.countdown.restart();
      });

    this.socketService.onEvent(Event.CONNECT)
      .subscribe(() => {
        console.log('connected');
      });

    this.socketService.onEvent(Event.DISCONNECT)
      .subscribe(() => {
        console.log('disconnected');
      });
  }

}
