import {AuthService} from 'src/app/services/auth.service';
import {Component, HostListener, OnInit} from '@angular/core';
import {ScrapperService} from 'src/app/services/Scrapper.service';
import {CardsOutput} from 'src/app/services/duelingbook/models/dueling-book-output-deck-classe';
import {PixelcomService} from 'src/app/services/pixel/pixelcom.service';
import {FormControl} from '@angular/forms';

const baseURLAW = 'https://images.ygoprodeck.com/images/cards_cropped/kek.jpg';

@Component({
  selector: 'app-pixel-panel',
  templateUrl: './pixel-panel.component.html',
  styleUrls: ['./pixel-panel.component.scss']
})
export class PixelPanelComponent implements OnInit {


  name = new FormControl('');
  language = true;
  cards: Array<CardsOutput> = Array();
  popup: boolean = true;
  selectedCard!: CardsOutput;

  constructor(
    private socketService: PixelcomService,
    private scrapper: ScrapperService,
    public authService: AuthService) {
  }

  @HostListener('window:callSwapLangue')
  onCallAngularService(): void {
    this.language = !this.language;
  }

  ngOnInit(): void {
  }

  findCard() {
    this.popup = true;
    this.scrapper.findCardByName(this.name.value).subscribe((data: any) => {
      this.cards = data;
    });
    this.name.setValue('');
  }


  onImgDeckError(event: any, card: CardsOutput) {
    event.target.src = card.urlEN;
  }

  openMenu(card: CardsOutput) {
    if (card.id) {
      card.urlAW = baseURLAW.replace('kek', '' + card.id);
      this.popup = false;
      this.selectedCard = card;
    }
  }


  sendToObsCardR(event: any) {
    event.preventDefault();
    this.socketService.sendCardR(event.target.src);
  }

  freeMonitor() {
    this.socketService.clear();
    this.socketService.clearReel();
  }

}
