import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor() {
  }

  public signIn(userData: string) {
    localStorage.setItem('ACCESS_TOKEN', userData);
  }

  public isLoggedIn() {
    return localStorage.getItem('ACCESS_TOKEN') !== null;
  }

  public getToken(): any {
    return localStorage.getItem('ACCESS_TOKEN');
  }

  public logout() {
    localStorage.removeItem('ACCESS_TOKEN');
  }
}
