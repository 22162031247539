<div class="col-sm-3" style="margin-top: 6%;">
  <img (click)="sendToObsCardL($event)" (error)="onImgDeckError($event,card)" [src]="language ? card.urlFR : card.urlEN" height="345"
       style="margin: .5%;" width="237">
</div>

<div class="col-sm-8" style="margin-top: 3%;">
  <img (click)="sendPixel($event)" *ngFor="let image of [url150,url100,url80,url60,url40,card.urlAW]" [src]="image" height="208" style="margin: .5%; "
       width="208">
</div>

