import {Component, OnInit} from '@angular/core';
import {SocketService} from 'src/app/services/Socket.service';

@Component({
  selector: 'app-pv-b',
  templateUrl: './pv-b.component.html',
  styleUrls: ['./pv-b.component.scss']
})
export class PvBComponent implements OnInit {

  ioConnection: any;
  pvB: string = '';

  constructor(private socketService: SocketService) {
  }

  ngOnInit(): void {

    this.ioConnection = this.socketService.onPvB()
      .subscribe((message: string) => {
        this.pvB = message;
      });

  }

}
