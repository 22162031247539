import {YuGiOhProDeckSearchService} from './yu-gi-oh-pro-deck-search.service';
import {Injectable} from '@angular/core';
import {DuelingBookDeck} from '../models/dueling-book-deck';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs/internal/Observable';
import {CardsOutput, DuelingBookOutputDeckClasse} from '../models/dueling-book-output-deck-classe';

@Injectable({
  providedIn: 'root'
})
export class DuelingBookTranslatorService {

  idList: string[] = [];

  constructor(private http: HttpClient,
              private yuGiOhProDeckApi: YuGiOhProDeckSearchService) {
  }


  public findDeckByID(idDB: string): Observable<DuelingBookOutputDeckClasse> {
    return new Observable<DuelingBookOutputDeckClasse>((Observer) => {
      let deck = new DuelingBookOutputDeckClasse();
      const url = '/proxy/php-scripts/load-deck.php?id=' + idDB;
      this.http.get(url).subscribe((data: DuelingBookDeck) => {
        if (data.action === 'Success') {
          deck.name = data.name!;
          data.main!.concat(data.extra!).concat(data.side!).forEach(cardData => {
            this.idList.push('' + cardData.serial_number!);
          });

          this.yuGiOhProDeckApi.latchoincamarchepasApiDemMerde(this.idList.join(',')).subscribe((carteMap: Map<number, CardsOutput>) => {
            data.main!.forEach(cardData => {
              let carte = carteMap.get(+cardData.serial_number!);
              if (carte) {
                deck.main.push(carte);
              } else {
                console.error('LA carte existe pas');
              }
            });
            data.extra!.forEach(cardData => {
              let carte = carteMap.get(+cardData.serial_number!);
              if (carte) {
                deck.extra.push(carte);
              } else {
                console.error('LA carte existe pas');
              }
            });
            data.side!.forEach(cardData => {
              let carte = carteMap.get(+cardData.serial_number!);
              if (carte) {
                deck.side.push(carte);
              } else {
                console.error('LA carte existe pas');
              }
            });
            Observer.next(deck);
          });
        }
      });
    });
  }
}
