import {Component, OnInit} from '@angular/core';
import {FormControl} from '@angular/forms';
import {SocketService} from './../../services/Socket.service';
import {AuthService} from 'src/app/services/auth.service';

@Component({
  selector: 'app-panel',
  templateUrl: './panel.component.html',
  styleUrls: ['./panel.component.scss'],
})
export class PanelComponent implements OnInit {
  nameA = new FormControl('');
  nameB = new FormControl('');
  pvA = new FormControl('');
  pvB = new FormControl('');
  timer = new FormControl('');

  constructor(
    private socketService: SocketService,
    public authService: AuthService
  ) {
  }

  ngOnInit(): void {
    this.socketService.getPva();
    this.socketService.getPvb();

    this.socketService.onPvA().subscribe((message: string) => {
      this.pvA.setValue(Number(message));
    });

    this.socketService.onPvB().subscribe((message: string) => {
      this.pvB.setValue(Number(message));
    });
  }

  clearDeck() {
    this.socketService.clearDeck();
  }

  setNameA() {
    this.socketService.sendNameA(this.nameA.value);
  }

  setNameB() {
    this.socketService.sendNameB(this.nameB.value);
  }

  setPvA() {
    this.socketService.sendPvA(this.pvA.value);
  }

  setPvB() {
    this.socketService.sendPvB(this.pvB.value);
  }

  setDuel() {
    this.socketService.sendNameA(this.nameA.value);
    this.socketService.sendNameB(this.nameB.value);
    this.socketService.sendPvA(this.pvA.value);
    this.socketService.sendPvB(this.pvB.value);
  }

  setTimer() {
    this.socketService.sendTimer(this.timer.value);
  }

  freeMonitor() {
    this.socketService.clear();
  }

  freeImage() {
    this.socketService.clearReel();
  }

  playTimer() {
    this.socketService.playTimer();
  }

  pauseTimer() {
    this.socketService.pauseTimer();
  }

  stopTimer() {
    this.socketService.stopTimer();
  }
}
