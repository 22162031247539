//import { DeckCYGOService } from './../../../services/deck/services/deck-cygo.service';
import { PvHisto } from './../../../services/pv-histo';
import { Component, HostListener, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { CardsOutput, DuelingBookOutputDeckClasse, } from 'src/app/services/duelingbook/models/dueling-book-output-deck-classe';
import { DuelingBookTranslatorService } from 'src/app/services/duelingbook/Services/dueling-book-translator.service';
import { ScrapperService } from 'src/app/services/Scrapper.service';
import { SocketService } from 'src/app/services/Socket.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { DeckCYGOService } from '../../../services/deck/services/deck-cygo.service';
import { Observable } from 'rxjs';
import { DeckDomain } from '../../../services/deck/models/deck';


@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements OnInit {
  cards: Array<CardsOutput> = Array();
  otherDeckklist = new FormControl('');
  deck1 = new DuelingBookOutputDeckClasse();
  deck2 = new DuelingBookOutputDeckClasse();
  language = true;
  autreDeck: DuelingBookOutputDeckClasse[] = [];
  nombre: number = 0;
  otherCard = new FormControl('');
  oversizedImage = new FormControl('');
  name = new FormControl('');
  urlCarte: Array<string> = Array();
  urlImage: Array<string> = Array();
  urlCarteHisto: Array<string> = Array();
  pvHisto: Array<PvHisto> = Array();
  deckCYGO$: Observable<DeckDomain[]>;

  constructor(
    private socketService: SocketService,
    private scrapper: ScrapperService,
    private dbts: DuelingBookTranslatorService,
    private sanitizer: DomSanitizer,
    private deckCYGO: DeckCYGOService,
  ) {
    this.deckCYGO$ = deckCYGO.getDeckCYGO();
  }

  @HostListener('window:callSwapLangue')
  onCallAngularService(): void {
    this.language = !this.language;
  }

  ngOnInit(): void {
    this.socketService.onDeck1().subscribe((data: DuelingBookOutputDeckClasse) => {
      this.deck1 = data;
    });
    this.socketService.onDeck2().subscribe((data: DuelingBookOutputDeckClasse) => {
      this.deck2 = data;
    });
    this.socketService.onClearDeck().subscribe(() => {
      this.deck1 = new DuelingBookOutputDeckClasse();
      this.deck2 = new DuelingBookOutputDeckClasse();
    });
    this.socketService.onCard().subscribe((message: string) => {
      this.urlCarteHisto.unshift(message);
    });
    this.socketService.onPvA().subscribe((message: string) => {
      if (+message != 0 && +message != 8000) {
        let newLine = {
          idPlayer: 1,
          pv: +message,
          date: new Date()
        };
        this.pvHisto.unshift(newLine);
      }
    });
    this.socketService.onPvB().subscribe((message: string) => {
      if (+message != 0 && +message != 8000) {
        let newLine = {
          idPlayer: 2,
          pv: +message,
          date: new Date()
        };
        this.pvHisto.unshift(newLine);
      }
    });
    //console.log(this.deckCYGO.decksDomain);
  }

  findCard() {
    this.scrapper.findCardByName(this.name.value).subscribe((data: any) => {
      this.cards = data;
    });
    this.name.setValue('');
  }

  setDeck1(deck: DuelingBookOutputDeckClasse) {
    this.socketService.sendDeck1(deck);
  }

  setDeck2(deck: DuelingBookOutputDeckClasse) {
    this.socketService.sendDeck2(deck);
  }

  setOtherDeckList() {
    this.dbts
      .findDeckByID(this.otherDeckklist.value)
      .subscribe((data: DuelingBookOutputDeckClasse) => {
        this.autreDeck.push(data);
        this.otherDeckklist.setValue('');
      });
  }

  setOtherDeckListFromGsheet(id: string) {
    this.dbts
      .findDeckByID(id)
      .subscribe((data: DuelingBookOutputDeckClasse) => {
        this.autreDeck.push(data);
      });
  }

  sendToObsCardL(event: any) {
    this.socketService.sendCardL(event.target.src);
  }

  sendToObsCardR(event: any) {
    event.preventDefault();
    this.socketService.sendCardR(event.target.src);
  }

  onImgDeckError(event: any, card: CardsOutput) {
    event.target.src = card.urlEN;
  }

  sendCardOther(event: any) {
    this.socketService.sendCardOther(event.target.src);
  }

  sendOversize(event: any) {
    this.socketService.sendCardOverSize(event.target.src);
  }

  addCardToOnglet() {
    if (this.urlCarte.indexOf(this.otherCard.value) == -1) {
      this.urlCarte.push(this.otherCard.value);
      this.otherCard.setValue('');
    }
  }

  addImageToOnglet() {
    if (this.urlImage.indexOf(this.oversizedImage.value) == -1) {
      this.urlImage.push(this.oversizedImage.value);
      this.oversizedImage.setValue('');
    }
  }

  closeTab(index: number) {
    if (index == 1) {
      this.cards = [];
    } else if (index == 2) {
      this.urlCarte = [];
    } else if (index == 3) {
      this.urlCarteHisto = [];
    } else if (index == 4) {
      this.pvHisto = [];
    }
  }

  closeTabDeck(index: DuelingBookOutputDeckClasse) {
    this.autreDeck = this.autreDeck.filter(obj => obj !== index);
  }

  closeTabOther(index: string) {
    this.urlImage = this.urlImage.filter(obj => obj !== index);
  }

  cleanURL(oldURL: string): SafeUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(oldURL);
  }

  sendHistoPv(action: PvHisto) {
    if (action.idPlayer && action.pv) {
      if (action.idPlayer === 1) {
        this.socketService.sendPvA(action.pv + '');
      } else {
        this.socketService.sendPvB(action.pv + '');
      }
    }
  }

}
