import {Component, OnInit} from '@angular/core';
import {SocketService} from 'src/app/services/Socket.service';

@Component({
  selector: 'app-name-a',
  templateUrl: './name-a.component.html',
  styleUrls: ['./name-a.component.scss']
})
export class NameAComponent implements OnInit {

  nameA: string = '';

  constructor(private socketService: SocketService) {
  }

  ngOnInit() {

    this.socketService.onNameA()
      .subscribe((message: string) => {
        this.nameA = message;
      });

  }

}
