import {Component, OnInit} from '@angular/core';
import {SocketService} from 'src/app/services/Socket.service';

@Component({
  selector: 'app-name-b',
  templateUrl: './name-b.component.html',
  styleUrls: ['./name-b.component.scss']
})
export class NameBComponent implements OnInit {

  ioConnection: any;
  nameB: string = '';

  constructor(private socketService: SocketService) {
  }

  ngOnInit() {


    this.ioConnection = this.socketService.onNameB()
      .subscribe((message: string) => {
        this.nameB = message;
      });

  }

}
