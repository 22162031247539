import {Component, OnInit} from '@angular/core';
import {SocketService} from 'src/app/services/Socket.service';

@Component({
  selector: 'app-pvcontrol',
  templateUrl: './pvcontrol.component.html',
  styleUrls: ['./pvcontrol.component.scss']
})
export class PvcontrolComponent implements OnInit {

  id = '';
  score = '';

  constructor(private socketService: SocketService) {
  }

  ngOnInit(): void {

    this.socketService.onCLearCommandPV()
      .subscribe(() => {
        this.id = '';
        this.score = '';
      });

    this.socketService.onUpdateID()
      .subscribe((message: string) => {
        this.id = message;
      });

    this.socketService.onUpdateScore()
      .subscribe((message: string) => {
        this.score = message;
      });
  }

}
