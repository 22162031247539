import {AuthService} from '../../../services/auth.service';
import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  authForm: FormGroup;
  returnUrl: string;

  constructor(private authService: AuthService,
              private router: Router, private formBuilder: FormBuilder,
              private route: ActivatedRoute) {
    this.authForm = this.formBuilder.group({
      token: ['', Validators.required]
    });
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  get formControls() {
    return this.authForm.controls;
  }

  ngOnInit(): void {
    if (this.authService.isLoggedIn()) {
      this.router.navigateByUrl(this.returnUrl);
    }
  }

  signIn() {
    if (this.authForm.invalid) {
      return;
    }
    this.authService.signIn(this.authForm.value.token);
    this.router.navigateByUrl(this.returnUrl);
  }

}
