export const DeckAttributesMapping = {
  type: 'Cat',
  idDDB: 'ID Db',
  name: 'Nom du deck'
};

export interface DeckEntity {
  type: string;
  idDDB: string;
  name: string;
}

export class DeckDomain {
  type: string;
  cards: DeckCard[];

  constructor() {
    this.type = '';
    this.cards = [];
  }
}

export class DeckCard {
  id: string;
  name: string;

  constructor(id :string,name :string) {
    this.id = id;
    this.name = name;
  }

}
