import {SocketService} from 'src/app/services/Socket.service';
import {Component, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {interval} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import { YuGiOhProDeckSearchService } from 'src/app/services/duelingbook/Services/yu-gi-oh-pro-deck-search.service';
import { CardsOutput } from 'src/app/services/duelingbook/models/dueling-book-output-deck-classe';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-randompixel',
  templateUrl: './randompixel.component.html',
  styleUrls: ['./randompixel.component.scss']
})
export class RandompixelComponent implements OnInit {

  /* Test Pixel random */
  pixel: Array<string> = new Array();
  randompixel: string = '';

  image: string = '';
  imageCard: string = '';
  imageCardEN: string = '';

  constructor(private http: HttpClient,
              private route: ActivatedRoute,
              private socketService: SocketService,
              private yuGiOhProDeckApi: YuGiOhProDeckSearchService,
              private sanitizer: DomSanitizer) {
  }

  public timeout(ms: any) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  public pickRandomPixel(timing: number) {
    var sr = this.pixel[Math.floor(Math.random() * this.pixel.length)];
    console.log(sr);
    this.randompixel = sr;
    //this.socketService.sendCardPixel("https://adkionbptq.cloudimg.io/v7/https://images.ygoprodeck.com/images/cards_cropped/" + this.randompixel + ".jpg?pixellate=100&width=421&height=421")
    this.setPixel(this.getPixelURl(this.randompixel,100) );
    setTimeout(() => {
      //this.socketService.sendCardPixel("https://adkionbptq.cloudimg.io/v7/https://images.ygoprodeck.com/images/cards_cropped/" + this.randompixel + ".jpg?pixellate=60&width=421&height=421")
      this.setPixel(this.getPixelURl(this.randompixel,60) );
      setTimeout(() => {
        //this.socketService.sendCardPixel("https://adkionbptq.cloudimg.io/v7/https://images.ygoprodeck.com/images/cards_cropped/" + this.randompixel + ".jpg?pixellate=45&width=421&height=421")
        this.setPixel(this.getPixelURl(this.randompixel,45)); 
        setTimeout(() => {
          //this.socketService.sendCardPixel("https://adkionbptq.cloudimg.io/v7/https://images.ygoprodeck.com/images/cards_cropped/" + this.randompixel + ".jpg?pixellate=30&width=421&height=421")
          this.setPixel(this.getPixelURl(this.randompixel,30));
          setTimeout(() => {
            //this.socketService.sendCardPixel("https://adkionbptq.cloudimg.io/v7/https://images.ygoprodeck.com/images/cards_cropped/" + this.randompixel + ".jpg?pixellate=20&width=421&height=421")
            this.setPixel(this.getPixelURl(this.randompixel,20)) ;
            setTimeout(() => {
              //this.socketService.sendCardPixel("https://adkionbptq.cloudimg.io/v7/https://images.ygoprodeck.com/images/cards_cropped/" + this.randompixel + ".jpg?width=421&height=421")
              this.setPixel(this.getPixelURl(this.randompixel,0) );
              setTimeout(() => {
                this.yuGiOhProDeckApi.latchoincamarchepasApiDemMerde(this.randompixel).subscribe((carteMap: Map<number, CardsOutput>) => {
                  carteMap.forEach((CardsOutput) => {
                    this.setCard(CardsOutput)
                  });
                });
              }, timing/7);
            }, timing/7);
          }, timing/7);
        }, timing/7);
      }, timing/7);
    }, timing/7);

  }


  public setCard(objet: CardsOutput) {
    this.image="";
    this.imageCard = objet.urlFR;
    this.imageCardEN = objet.urlEN;
  }

  public setPixel(url: string) {
    this.image=url;
    this.imageCard = "";
    this.imageCardEN = "";
  }


public getPixelURl(id: string, pixelate : number) {
  let url = "https://adkionbptq.cloudimg.io/v7/https://images.ygoprodeck.com/images/cards_cropped/"+id +".jpg?";
  if(pixelate !=0){
    url = url+"pixellate="+pixelate+"&";
  }
  return url+ "width=421&height=421";
}

  public loopPixel(timing: number) {
    const subscription = interval(timing).subscribe(val => this.pickRandomPixel(timing));
  }

  onImgError(event: any) {
    console.log(event);
    this.imageCard = this.imageCardEN;
  }

  cleanURL(oldURL: string): SafeUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(oldURL);
  }

  ngOnInit(): void {
    /* Test Pixel random */
    this.http.get('../../assets/pixel.txt', {
      responseType: 'text'
    }).subscribe(data => {
      const lines = data.split('\n');
      for (let i = 0; i < lines.length; i++) {
        const item = lines[i];
        this.pixel.push(item);
      }
      
      this.route.params.subscribe(params => {
        let timing: number = +params['time'];
        this.pickRandomPixel(timing);
        this.loopPixel(timing);
      });
    });
  }

}
