import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Extension} from 'src/app/models/Extension';
import {ScrapperService} from 'src/app/services/Scrapper.service';
import {CardsOutput} from '../models/dueling-book-output-deck-classe';

@Injectable({
  providedIn: 'root'
})
export class YuGiOhProDeckSearchService {

  extensions: Array<Extension>;

  constructor(private http: HttpClient,
              private scrapperService: ScrapperService) {
    this.extensions = this.scrapperService.getExtensions();
  }

  public latchoincamarchepasApiDemMerde(listeID: string): Observable<Map<number, CardsOutput>> {
    return new Observable((Observer) => {
      let resultat: Map<number, CardsOutput> = new Map();
      let url = 'https://db.ygoprodeck.com/api/v7/cardinfo.php?id=' + listeID;
      //let base_img = 'https://www.otk-expert.fr/cartes/yugioh_ext/&extension1&/&extension2&-&number&.jpg';
      let base_img = 'https://ygocard.s3.fr-par.scw.cloud/&extension1&-&number&.jpg';
      this.http.get(url, {responseType: 'text'}).subscribe(data => {
        var json = JSON.parse(data);
        json.data.forEach((carte: any) => {
          let date = '';
          let value: any;
          if (carte.card_sets) {
            carte.card_sets.forEach((extension: any) => {
              this.extensions.forEach((ext: Extension) => {
                if (ext.name === extension.set_name) {
                  if ((date === '' || Date.parse(date) < Date.parse(ext.date)) && !ext.name.includes('Tournament')) {
                    date = ext.date;
                    value = extension.set_code.split('-', 2);
                    value[1] = 'FR' + value[1].substr(2);
                  }
                }
              });
            });
          }
          let card = new CardsOutput();
          card.id = +carte.id;
          card.name = carte.name;
          if (carte.card_sets && value && value.length == 2) {
            card.urlFR = base_img.replace('&extension1&', value[0]).replace('&extension2&', value[0]);
            //card.urlFR = card.urlFR.replace('&number&', "" + Number(value[1].substr(value[1].length - 3)));
            card.urlFR = card.urlFR.replace('&number&', '' + value[1]);
          }
          card.urlEN = carte.card_images[0].image_url;
          resultat.set(card.id, card);
        });
        Observer.next(resultat);
      });
    });
  }
}
