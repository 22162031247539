import {PixelPanelComponent} from './pages/pixel/pixel-panel/pixel-panel.component';

import {RandomcardComponent} from './pages/display/randomcard/randomcard.component';
import {LoginComponent} from './pages/autre/login/login.component';
import {TimerComponent} from './pages/display/timer/timer.component';
import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ObsComponent} from './pages/display/obs/obs.component';
import {PanelComponent} from './pages/panel/panel.component';
import {AuthGuard} from './guard/auth.guard';
import {LogoutComponent} from './pages/autre/logout/logout.component';
import {NameBComponent} from './pages/display/joueur/name-b/name-b.component';
import {PvAComponent} from './pages/display/joueur/pv-a/pv-a.component';
import {PvBComponent} from './pages/display/joueur/pv-b/pv-b.component';
import {Joueur2Component} from './pages/display/joueur/joueur2/joueur2.component';
import {Reversejoueur2Component} from './pages/display/joueur/reversejoueur2/reversejoueur2.component';
import {DeckComponent} from './pages/display/deck/deck.component';
import { DeckNameComponent } from './pages/display/deckname/deckname.component';
import {NameAComponent} from './pages/display/joueur/name-a/name-a.component';
import {Joueur1Component} from './pages/display/joueur/joueur1/joueur1.component';
import {InfosComponent} from './pages/autre/infos/infos.component';
import {RandompixelComponent} from './pages/display/randompixel/randompixel.component';

const routes: Routes = [
  {path: 'obs/:orientation', component: ObsComponent, canActivate: [AuthGuard]},
  {path: 'panel', component: PanelComponent, canActivate: [AuthGuard]},
  {path: 'nameA', component: NameAComponent, canActivate: [AuthGuard]},
  {path: 'nameB', component: NameBComponent, canActivate: [AuthGuard]},
  {path: 'pvA', component: PvAComponent, canActivate: [AuthGuard]},
  {path: 'pvB', component: PvBComponent, canActivate: [AuthGuard]},
  {path: 'timer', component: TimerComponent, canActivate: [AuthGuard]},
  {path: 'logout', component: LogoutComponent, canActivate: [AuthGuard]},
  {path: 'infos', component: InfosComponent, canActivate: [AuthGuard]},
  {path: 'joueur1', component: Joueur1Component, canActivate: [AuthGuard]},
  {path: 'joueur2', component: Joueur2Component, canActivate: [AuthGuard]},
  {path: 'reverse2', component: Reversejoueur2Component, canActivate: [AuthGuard]},
  {path: 'deck/:id', component: DeckComponent, canActivate: [AuthGuard]},
  {path: 'deckname/:id', component: DeckNameComponent, canActivate: [AuthGuard]},
  {path: 'panel-pixel', component: PixelPanelComponent, canActivate: [AuthGuard]},
  {path: 'login', component: LoginComponent},
  {path: 'randomcard/:time', component: RandomcardComponent},
  {path: 'randompixel/:time', component: RandompixelComponent},
  {path: '', redirectTo: 'panel', pathMatch: 'full'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
