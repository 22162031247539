import { Validators } from '@angular/forms';
import { DeckAttributesMapping, DeckCard, DeckDomain } from './../../deck/models/deck';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GoogleSheetsDbService } from 'ng-google-sheets-db';
import { DeckEntity } from '../../deck/models/deck';

@Injectable({
  providedIn: 'root'
})
export class DeckCYGOService {

  decks$!: Observable<DeckEntity[]>;

  constructor(private googleSheetsDbService: GoogleSheetsDbService) {
    this.decks$ = this.googleSheetsDbService.get<DeckEntity>(
      '1lVVwnRANnZNfQo7IPAnDcy9-GEj5K4OO6kXI59t_SXc', 'Stats deck', DeckAttributesMapping);
  }

  ngOnInit(): void {
  }

  public getDeckCYGO(): Observable<DeckDomain[]> {
    return new Observable((observer) => {
      this.decks$.subscribe((decksEntity: DeckEntity[]) => {
        let decksDomain: DeckDomain[] = [];
        if (decksEntity && decksEntity.length > 0) {
          decksEntity.forEach(deckEntity => {
            if (deckEntity.type && deckEntity.type.length > 0 && deckEntity.idDDB && deckEntity.idDDB.length > 0 && deckEntity.name && deckEntity.name.length > 0) {
              let tmpdomain = new DeckDomain();
              let tmpcard = new DeckCard(deckEntity.idDDB, deckEntity.name);
              if (decksDomain.length > 0) {
                let tableau = decksDomain.find(x => x.type == deckEntity.type);
                if (tableau) {
                  tableau.cards.push(tmpcard);
                } else {
                  tmpdomain.type = deckEntity.type;
                  tmpdomain.cards.push(tmpcard);
                  decksDomain.push(tmpdomain);
                }
              } else {
                tmpdomain.type = deckEntity.type;
                tmpdomain.cards.push(tmpcard);
                decksDomain.push(tmpdomain);
              }
            } else {
              //observer.error('le retour est mal formaté');
            }
          });
          observer.next(decksDomain);
        } else {
          observer.error('liste Gheets vide');
        }
      });
    });
  }
}
