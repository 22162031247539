<app-header></app-header>
<div class="container-fluid">
  <div class="row">
    <div class="col-sm-2" style="margin-top: 3%;">
      <div class="chat_dialog">
        <app-obs [isPanel]="true"></app-obs>
      </div>
    </div>

    <div class="col-sm-8" style="margin-top: 3%;">
      <div class="d-flex justify-content-center align-items-center flex-column bd-highlight mb-3">
        <h2 class="mb-auto p-2 bd-highlight" style="margin-right:10px;">
          Pixel
        </h2>

        <div class="row">
          <input (keyup.enter)="findCard()" [formControl]="name" placeholder="Entrez un nom de carte..." style="margin-right:10px;"
                 type="text">
          <button (click)="findCard()" class="btn btn-dark" style="margin-right:10px;">Rechercher</button>
          <input checked data-off="EN" data-offstyle="outline-warning" data-on="FR" data-onstyle="outline-primary"
                 data-toggle="toggle" id="toggle-event" style="margin-right:10px;" type="checkbox">

          <button (click)="freeMonitor()" class="btn btn-danger suppCard" style="margin-left:10px;">
            <i class="fas fa-trash-alt"></i>
            Retirer
          </button>
        </div>
      </div>
      <div *ngIf=" cards.length !=0  && popup" class="d-flex justify-content-center">
        <div *ngIf=" cards.length !=0 ">
          <ul style="height:720px;width:1310px;overflow:auto">
            <img (click)="openMenu(card)" (contextmenu)="sendToObsCardR($event)" (error)="onImgDeckError($event,card)" *ngFor="let card of cards" [src]="language ? card.urlFR : card.urlEN"
                 height="345" style="margin: .5%;"
                 width="237">
          </ul>
        </div>
      </div>
      <app-pixel-popup *ngIf="!popup" [card]="selectedCard" [language]="language" [sendToObsCardL]="sendToObsCardR"
                       class="d-flex justify-content-center">
      </app-pixel-popup>

    </div>

  </div>
</div>
