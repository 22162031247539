export class DuelingBookOutputDeckClasse {
  name: string;
  main: CardsOutput[];
  side: CardsOutput[];
  extra: CardsOutput[];

  constructor() {
    this.name = '';
    this.main = Array<CardsOutput>();
    this.side = Array<CardsOutput>();
    this.extra = Array<CardsOutput>();
  }
}


export class CardsOutput {
  name: string;
  id: number;
  urlEN: string;
  urlFR: string;
  urlAW: string;

  constructor() {
    this.name = '';
    this.id = -1;
    this.urlEN = '';
    this.urlFR = '';
    this.urlAW = '';
  }
}
