import {SocketService} from './../../../../services/Socket.service';
import {Component, Input, OnInit} from '@angular/core';
import {CardsOutput} from 'src/app/services/duelingbook/models/dueling-book-output-deck-classe';

@Component({
  selector: 'app-pixel-popup',
  templateUrl: './pixel-popup.component.html',
  styleUrls: ['./pixel-popup.component.scss']
})
export class PixelPopupComponent implements OnInit {


  @Input()
  card!: CardsOutput;

  @Input()
  language!: boolean;

  @Input()
  sendToObsCardL!: (event: any) => void;


  url150 !: string;
  url100 !: string;
  url80 !: string;
  url60 !: string;
  url40 !: string;

  constructor(private socketService: SocketService) {
  }

  ngOnInit(): void {
    let baseURL = 'https://adkionbptq.cloudimg.io/v7/';
    let endURL = '?pixellate=';
    let resize = '&width=421&height=421';
    this.url150 = baseURL + this.card.urlAW + endURL + ('' + 100) + resize;
    this.url100 = baseURL + this.card.urlAW + endURL + ('' + 60) + resize;
    this.url80 = baseURL + this.card.urlAW + endURL + ('' + 45) + resize;
    this.url60 = baseURL + this.card.urlAW + endURL + ('' + 30) + resize;
    this.url40 = baseURL + this.card.urlAW + endURL + ('' + 20) + resize;
    this.card.urlAW = baseURL + this.card.urlAW + '?width=421&height=421';
  }


  onImgDeckError(event: any, card: CardsOutput) {
    event.target.src = card.urlEN;
  }


  sendPixel(event: any) {
    this.socketService.sendCardPixel(event.target.src);
  }

}
