import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {CountdownModule} from 'ngx-countdown';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {ObsComponent} from './pages/display/obs/obs.component';
import {PanelComponent} from './pages/panel/panel.component';
import {MonitorComponent} from './pages/panel/monitor/monitor.component';
import {TimerComponent} from './pages/display/timer/timer.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {LoginComponent} from './pages/autre/login/login.component';
import {LogoutComponent} from './pages/autre/logout/logout.component';
import {Joueur1Component} from './pages/display/joueur/joueur1/joueur1.component';
import {ClipboardModule} from '@angular/cdk/clipboard';
import {PvcontrolComponent} from './pages/panel/pvcontrol/pvcontrol.component';
import {Ng9OdometerModule} from 'ng9-odometer';
import {RandomcardComponent} from './pages/display/randomcard/randomcard.component';
import {DeckComponent} from './pages/display/deck/deck.component';
import {DeckNameComponent} from './pages/display/deckname/deckname.component';
import {MatTabsModule} from '@angular/material/tabs';
import {SearchComponent} from './pages/panel/search/search.component';
import {MatIconModule} from '@angular/material/icon';
import {MatExpansionModule} from '@angular/material/expansion';
import {NameAComponent} from './pages/display/joueur/name-a/name-a.component';
import {NameBComponent} from './pages/display/joueur/name-b/name-b.component';
import {PvAComponent} from './pages/display/joueur/pv-a/pv-a.component';
import {PvBComponent} from './pages/display/joueur/pv-b/pv-b.component';
import {Joueur2Component} from './pages/display/joueur/joueur2/joueur2.component';
import {Reversejoueur2Component} from './pages/display/joueur/reversejoueur2/reversejoueur2.component';
import {InfosComponent} from './pages/autre/infos/infos.component';
import {PixelPanelComponent} from './pages/pixel/pixel-panel/pixel-panel.component';
import {PixelPopupComponent} from './pages/pixel/pixel-panel/pixel-popup/pixel-popup.component';
import {HeaderComponent} from './pages/autre/header/header.component';
import {RandompixelComponent} from './pages/display/randompixel/randompixel.component';
import { API_KEY, GoogleSheetsDbService } from 'ng-google-sheets-db';

@NgModule({
  declarations: [
    AppComponent,
    ObsComponent,
    PanelComponent,
    NameAComponent,
    NameBComponent,
    PvAComponent,
    PvBComponent,
    MonitorComponent,
    TimerComponent,
    LoginComponent,
    LogoutComponent,
    InfosComponent,
    Joueur1Component,
    Joueur2Component,
    PvcontrolComponent,
    RandomcardComponent,
    DeckComponent,
    DeckNameComponent,
    Reversejoueur2Component,
    SearchComponent,
    PixelPanelComponent,
    PixelPopupComponent,
    HeaderComponent,
    RandompixelComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    CountdownModule,
    ScrollingModule,
    ClipboardModule,
    MatTabsModule,
    BrowserAnimationsModule,
    Ng9OdometerModule.forRoot(),
    MatIconModule,
    MatExpansionModule
  ],
  providers: [ {
    provide: API_KEY,
    useValue: 'AIzaSyDaXyazFaXnKfA9HXHb6UAwCFYEJwpgjTk',
  },
  GoogleSheetsDbService],
  bootstrap: [AppComponent]
})
export class AppModule {
}
