<nav class="navbar navbar-dark fixed-top bg-dark flex-md-nowrap p-0 shadow">
  <a class="navbar-brand col-sm-3 col-md-2 mr-0" href="#">Panneau de contrôle OBS - Yu-Gi-Oh!</a>
  <a class="navbar-brand col-sm-3 col-md-2 mr-0" href="/infos">Liens OBS</a>
  <a class="navbar-brand col-sm-6 col-md-2 mr-0" href="/logout">Déconnexion</a>
  <a class="navbar-brand col-sm-3 col-md-2 mr-0 text-danger" href="#">room : {{authService.getToken()}}</a>
</nav>

<div style="padding-top: 48px;">
</div>


<link href="//cdnjs.cloudflare.com/ajax/libs/font-awesome/5.14.0/css/all.min.css" rel="stylesheet">
<section class="df-linken-2 container mb-3 mt-3">
  <div class="row">
    <div [cdkCopyToClipboard]="getCartes()" class="col bg-secondary m-4 shadow-sm special">
      <p class="text-truncate text-nowrap mt-2">
        <a class="text-white" href="../obs/none">
          <i class="fas fa-link fa-fw mr-2"></i>
          Cartes Universelle
        </a>
      </p>
    </div>
    <div [cdkCopyToClipboard]="getCartesL()" class="col bg-secondary m-4 shadow-sm special">
      <p class="text-truncate text-nowrap mt-2">
        <a class="text-white" href="../obs/left">
          <i class="fas fa-link fa-fw mr-2"></i>
          Cartes Gauche
        </a>
      </p>
    </div>
    <div [cdkCopyToClipboard]="getCartesR()" class="col bg-secondary m-4 shadow-sm special">
      <p class="text-truncate text-nowrap mt-2">
        <a class="text-white" href="../obs/right">
          <i class="fas fa-link fa-fw mr-2"></i>
          Cartes Droite
        </a>
      </p>
    </div>

    <div [cdkCopyToClipboard]="getTimer()" class="col bg-primary m-4 shadow-sm special">
      <p class="text-truncate text-nowrap mt-2">
        <a class="text-white" href="../timer">
          <i class="fas fa-link fa-fw mr-2"></i>
          Timer
        </a>
      </p>
    </div>
    <div [cdkCopyToClipboard]="getJ1()" class="col bg-dark m-4 shadow-sm special">
      <p class="text-truncate text-nowrap mt-2">
        <a class="text-white" href="../joueur1">
          <i class="fas fa-link fa-fw mr-2"></i>
          Joueur 1
        </a>
      </p>
    </div>
    <div [cdkCopyToClipboard]="getJ2()" class="col bg-success m-4 shadow-sm special">
      <p class="text-truncate text-nowrap mt-2">
        <a class="text-white" href="../joueur2">
          <i class="fas fa-link fa-fw mr-2"></i>
          Joueur 2
        </a>
      </p>
    </div>
    <div [cdkCopyToClipboard]="getNJ1()" class="col bg-warning m-4 shadow-sm special">
      <p class="text-truncate text-nowrap mt-2">
        <a class="text-dark" href="../nameA">
          <i class="fas fa-link fa-fw mr-2"></i>
          Nom Joueur 1
        </a>
      </p>
    </div>
    <div [cdkCopyToClipboard]="getNJ2()" class="col bg-warning m-4 shadow-sm special">
      <p class="text-truncate text-nowrap mt-2">
        <a class="text-dark" href="../nameB">
          <i class="fas fa-link fa-fw mr-2"></i>
          Nom Joueur 2
        </a>
      </p>
    </div>
    <div [cdkCopyToClipboard]="getPJ1()" class="col bg-warning m-4 shadow-sm special">
      <p class="text-truncate text-nowrap mt-2">
        <a class="text-dark" href="../pvA">
          <i class="fas fa-link fa-fw mr-2"></i>
          PV Joueur 1
        </a>
      </p>
    </div>
    <div [cdkCopyToClipboard]="getPJ2()" class="col bg-warning m-4 shadow-sm special">
      <p class="text-truncate text-nowrap mt-2">
        <a class="text-dark" href="../pvB">
          <i class="fas fa-link fa-fw mr-2"></i>
          PV Joueur 2
        </a>
      </p>
    </div>
  </div>
</section>
