import {Component, OnInit} from '@angular/core';
import {Observable, Observer} from 'rxjs';
import {SocketService} from 'src/app/services/Socket.service';
import {Event} from '../../../../models/Event.enum';
import {share} from 'rxjs/operators';
import {Howl} from 'howler';

@Component({
  selector: 'app-joueur1',
  templateUrl: './joueur1.component.html',
  styleUrls: ['./joueur1.component.scss']
})
export class Joueur1Component implements OnInit {

  nameA: string = 'Joueur 1';
  public number1: number = 0;
  public observable: Observable<boolean>;
  private observer!: Observer<boolean>;

  constructor(private socketService: SocketService) {
    this.observable = new Observable<boolean>((observer: any) => this.observer = observer).pipe(share());
    setTimeout(() => this.observer.next(true));
    this.socketService.getPva();
  }

  ngOnInit(): void {

    var sound = new Howl({
      src: ['assets/points_sound_effect.ogg'],
      html5: true
    });

    this.socketService.onNameA()
      .subscribe((message: string) => {
        this.nameA = message;
      });


    this.socketService.onPvA()
      .subscribe((message: string) => {
        this.number1 = Number(message);
        sound.play();
        setTimeout(() => this.observer.next(true), 1000);
      });

    this.socketService.onEvent(Event.CONNECT)
      .subscribe(() => {
        console.log('connected');
      });

    this.socketService.onEvent(Event.DISCONNECT)
      .subscribe(() => {
        console.log('disconnected');
      });

  }

}
